/** Services */
import { storyblokInit, renderRichText } from "@storyblok/js";
import Content from "../../Services/Content.js";
import State from "../../Services/State.js";

/** Components */
import Carousel from "./Carousel.js";

/** Variables */
let instance = null;

export default class DiaryPage {
    constructor( _element, data, completed, previous, next ) {
        // Singleton
        if ( instance ) return instance;
        instance = this;

        /** Callback Functions */
        this.completed = completed;
        this.previous = previous;
        this.next = next;

        /** Bindings */

        /** Elements */
        this.element = _element;

        this.animationObserver = null;
        this.data = data;
        this.carousels = [];

        storyblokInit( {} );

        this.onInit();
    }

    /** Functions */
    // Creates an observer to know when animations should play
    createObserver() {
        setTimeout( () => {
            this.animationObserver = new IntersectionObserver( ( entries ) => {
                entries.forEach( ( entry ) => {
                    if ( entry.isIntersecting ) {
                        entry.target.classList.add( 'active' );
                        return;
                    }
                } )
            } )

            const allAnimations = document.querySelectorAll( '.Animation' );
            allAnimations.forEach( ( element ) => this.animationObserver.observe( element ) );
        } )
    }

    // Check if we have reached the bottom of the page
    checkCompleted() {
        setTimeout( () => {
            this.completionObserver = new IntersectionObserver( ( entries ) => {
                entries.forEach( ( entry ) => {
                    if ( entry.isIntersecting ) {
                        if ( this.completed ) this.completed();
                        return;
                    }
                } )
            } )

            const completion = document.querySelectorAll( '.Completed' );
            completion.forEach( ( element ) => this.completionObserver.observe( element ) );
        } )
    }
    
    create() {
        this.removeListeners();

        let collection = [];
        let carousels = [];
        for ( let i = 0; i < this.data.page.length; i++ ) {
            switch( this.data.page[ i ].component ) {
                case 'banner':
                    collection.push( `
                        <div class="Banner Animation">
                            <img class="banner-image" src="${ this.data.page[ i ].hero.filename }" />
                        </div>
                    ` );
                    break;
                case 'info':
                    collection.push( `
                        <div class="Information Animation">
                            <div class="date">
                                <img class="date-icon" src="/images/info/calendar.svg" />
                                <p class="text date-text">${ this.data.page[ i ].date }</p>
                            </div>
                            <div class="written">
                                ${ this.data.page[ i ].written_by === 'beth' ? `
                                    <img class="written-icon" src="/images/info/beth.jpg" />
                                ` : `
                                    <img class="written-icon lb" src="/images/info/lawrence.jpg" />
                                ` }
                                <p class="text written-text">Written by ${ this.data.page[ i ].written_by === 'beth' ? 'Beth' : 'Lawrence' }</p>
                            </div>
                        </div>
                    ` );
                    break;
                case 'title':
                    collection.push( `
                        <div class="Title Animation">
                            <h1 class="title-text headline">${ this.data.page[ i ].title }</h1>
                        </div>
                    ` );
                    break;
                case 'image':
                    collection.push( `
                        <div class="Image Animation">
                            <img class="image-asset ${ this.data.page[ i ].size }" src="${ this.data.page[ i ].asset.filename }" />
                            ${ this.data.page[ i ].caption ? `<span class="caption text-s">${ this.data.page[ i ].caption }</span>` : '' }
                        </div>
                    ` );
                    break;
                case 'body':
                    collection.push( `
                        <div class="Richtext Animation">
                            ${ renderRichText( this.data.page[ i ].copy ) }
                        </div>
                    ` );
                    break;
                case 'carousel':
                    carousels.push( { id: `carousel_${ i }`, assets: this.data.page[ i ].assets } );
                    collection.push( `<div id="carousel_${ i }" class="Animation"></div>` );
                    break;
                case 'iframe':
                    collection.push( `
                        <div class="Iframe ${ this.data.page[ i ].size } Animation">
                            <div class="iframe-container">
                                <iframe class="iframe" src="${ this.data.page[ i ].url }" width="560" height="315" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                    ` );
                    break;
                case 'video':
                    collection.push( `
                        <div class="Video ${ this.data.page[ i ].size } Animation">
                            <video class="video-component" src="${ this.data.page[ i ].url }" controls></video>
                        </div>
                    ` );
                    break;
            }
        }

        this.element.innerHTML = `
            ${ collection.reduce( ( acc, next ) => `${ acc }${ next }`, '' ) }
        
            <div class="actions ${ !this.previous ? 'push' : '' } Animation Completed">
                ${ this.previous ? `
                    <button id="DiaryPage-previous" type="button" class="button-primary size-small">
                        <span class="text-s">Previous:</span>
                        <span class="text-xs">${ this.previous.title }</span>
                        <div class="split"></div>
                    </button>
                ` : '' }
                ${ this.next ? `
                    <button id="DiaryPage-next" type="button" class="button-primary size-small">
                        <span class="text-s">Next Up:</span>
                        <span class="text-xs">${ this.next.title }</span>
                        <div class="split"></div>
                    </button>
                ` : '' }
            </div>
        `;

        this.carousels = carousels.map( ( item ) => new Carousel( document.getElementById( item.id ), item.assets ) );
        this.createObserver();
        this.checkCompleted();

        /** Listeners */
        const previousButton = document.getElementById( 'DiaryPage-previous' );
        if ( previousButton ) previousButton.addEventListener( 'click', this.previous.callback );

        const nextButton = document.getElementById( 'DiaryPage-next' );
        if ( nextButton ) nextButton.addEventListener( 'click', this.next.callback );
    }

    removeListeners() {
        const listButton = document.getElementById( 'DiaryPage-list' );
        if ( listButton ) listButton.removeEventListener( 'click', this.list.callback );

        const previousButton = document.getElementById( 'DiaryPage-previous' );
        if ( previousButton ) previousButton.removeEventListener( 'click', this.previous.callback );

        const nextButton = document.getElementById( 'DiaryPage-next' );
        if ( nextButton ) nextButton.removeEventListener( 'click', this.next.callback );
    }

    /** Lifecycles */
    onInit() {
        this.element.classList.add( 'DiaryPage' );
        this.create();
    }

    destroy() {
        if ( this.animationObserver ) {
            const allAnimations = document.querySelectorAll( '.Animation' );
            allAnimations.forEach( ( element ) => this.animationObserver.unobserve( element ) );
            this.animationObserver = null;
        }

        if ( this.completionObserver ) {
            const completion = document.querySelectorAll( '.Completed' );
            completion.forEach( ( element ) => this.completionObserver.unobserve( element ) );
            this.completionObserver = null;
        }

        clearTimeout( this.delay );
        instance = null;
    }
}